<template>
    <div class="bannerControl">
        <div class="bot">
            <p class="tips">*上传图片不超过1M，支持png、bmg、jpeg、jpg、gif</p>
            <div class="listItem">
                <vuedraggable v-model="controlDefault" v-bind="{
                    animation: 200,
                    group: 'description',
                    disabled: false,
                    ghostClass: 'ghost',
                    scroll: true
                }">
                    <transition-group>
                        <div class="itemBox" v-for="(item, index) in controlDefault" :key="index">
                            <div class="contantBox">
                                <div class="left">
                                    <div class="leftL">
                                        <span @click="moveClick(0, index, item)"
                                            :class="index == 0 ? 'fcccc' : ''">&lt;</span>
                                        <span @click="moveClick(1, index, item)"
                                            :class="index == controlDefault.length - 1 ? 'fcccc' : ''">></span>
                                    </div>
                                    <div class="leftR"><img :src="item.imgUrl" alt="" /></div>
                                </div>
                                <div class="right">
                                    <div>
                                        <div class="right-top">
                                            <div class="inputBox">
                                                <el-input v-model="item.imgUrl" disabled placeholder="请选择图片"></el-input>
                                                <div @click="getIndex(index)">
                                                    <el-button type="primary" @click="chooseImg(index)">选择</el-button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="right-top">
                                            <div class="inputBox">
                                                <el-input v-model="item.toPage" disabled placeholder="选择路径"></el-input>
                                                <!-- <p>{{ item.toPage }}</p> -->
                                                <el-button type="primary" @click="choosePage(index)">选择</el-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tips">
                                建议该图片尺寸{{ item.size ? item.size : size[index] }}
                            </div>
                        </div>
                    </transition-group>
                </vuedraggable>
            </div>
        </div>
        <!-- 选择跳转页面弹框 -->
        <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
            <div>
                <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
            </div>
        </el-dialog>
        <!-- 选择图片弹框 -->
        <UploadSource v-if="chooseImgFlag" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag"
            @getSource="getImgRes" type="image" :maxSize="1024 * 1024"></UploadSource>
    </div>
</template>

<script>
import vuedraggable from 'vuedraggable'
import UploadSource from '@/components/uploadSource'
import ToPage from '@/repair/components/toPage'
export default {
    props: {
        controlDefault: {
            type: Array,
            default: () => []
        }
    },
    components: {
        vuedraggable,
        UploadSource,
        ToPage
    },
    mounted() {
        console.log(111, this.controlDefault);
        console.log(111, this.controlDefault.length);
    },
    data () {
        return {
            index: -1,
            toPageFlag: false,
            chooseImgFlag: false,
            itemIndex: -1,
            img: 'orngfo',
            size: ['320px*290px', '306px*140px', '306px*140px', '306px*140px']
        }
    },
    watch: {
        controlDefault (val) {
            this.$emit('update-data', val)
        }
    },
    methods: {
        changeStatus (val) {
            this.chooseImgFlag = val
        },
        getIndex (index) {
            this.index = index
        },
        handleRemove (response) {
            if (response.code == 0) {
                this.controlDefault[this.index].imgURL = response.result.path
                this.$emit('sonMethod', this.controlDefault)
            }
        },
        // 点击移动图片图位置
        moveClick (status, index, item) {
            if (status == 0) {
                // 向上移
                if (index == 0) {
                    this.$message({
                        message: '已经在最前面了~',
                        type: 'error'
                    })
                    return
                }
                this.controlDefault.splice(index, 1)
                this.controlDefault.splice(index - 1, 0, item)
            } else {
                // 向下移
                if (index == this.controlDefault.length - 1) {
                    this.$message({
                        message: '已经在最后面了~',
                        type: 'error'
                    })
                    return
                }
                this.controlDefault.splice(index + 2, 0, item)
                this.controlDefault.splice(index, 1)
            }
        },

        // 选择图片
        chooseImg (index) {
            this.index = index;
            this.chooseImgFlag = !this.chooseImgFlag;
            console.log(this.controlDefault);
        },
        // 选择跳转路径
        choosePage (index) {
            this.toPageFlag = true
            this.itemIndex = index
            this.$nextTick(() => {
                this.$refs.toPage.getPageArr()
            })
        },
        // 拿到跳转路径
        getPageRes (item) {
            this.controlDefault[this.itemIndex].toPage = item.wxroute
            this.toPageFlag = false
        },
        // 拿到图片
        getImgRes (imglist) {
            this.controlDefault[this.index].imgUrl = imglist[0].path
        },
    }
}
</script>

<style lang="less" scoped>
.fcccc {
    color: #ccc;
    border: 1px solid #ccc !important;
    cursor: no-drop !important;
}

.bannerControl {
    width: 100%;

    .numBox {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        p {
            margin-right: 20px;
        }
    }

    .bgColor {
        display: flex;
        align-items: center;
        margin: 20px 0;

        p {
            margin-right: 20px;
        }

        .el-color-picker/deep/.el-color-picker__trigger {
            width: 150px;
        }
    }

    .bot {
        padding: 30px;

        .tips {
            font-size: 14px;
            color: #9a9a9a;
        }

        .listItem {
            width: 100%;

            .itemBox {
                width: 100%;
                height: 105px;
                margin-top: 20px;

                .tips {
                    margin-left: 50px;
                    margin-top: 5px;
                }

                .contantBox {
                    width: 100%;
                    height: 85px;
                    display: flex;
                }

                .left {
                    display: flex;

                    .leftL {
                        font-family: SimSun;
                        width: 30px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-right: 20px;

                        span {
                            width: 16px;
                            height: 16px;
                            line-height: 16px;
                            text-align: center;
                            border-radius: 5px;
                            display: block;
                            border: 1px solid #333;
                            font-weight: bold;
                            cursor: pointer;
                        }

                        span:first-child {
                            transform: rotate(90deg);
                        }

                        span:last-child {
                            transform: rotate(90deg);
                            margin-top: 10px;
                        }
                    }

                    .leftR {
                        height: 100%;
                        width: 220px;
                        overflow: hidden;

                        // background-color: greenyellow;s
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .right {
                    flex: 1;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    &>div:first-child {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }

                    .inputBox {
                        width: 220px;
                        height: 40px;
                        border: 1px solid #ccc;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        position: relative;

                        p {
                            width: 150px;
                            margin-left: 10px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #9e9e9e;
                        }
                    }
                }
            }
        }
    }
}
</style>

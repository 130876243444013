<template>
    <el-container>
        <div class="main">
            <nav>
                <ul>
                    <li :class="[active == 0 ? 'activeli' : '']" @click="handleNav(0)">首页设置</li>
                    <!--<li :class="[active == 1 ? 'activeli' : '']" @click="handleNav(1)">论坛设置</li>-->
                </ul>
            </nav>
            <div style="flex:1">
                <Indexset v-if="active == 0"></Indexset>
            </div>
        </div>
    </el-container>
</template>

<script>
import Indexset from './indexset'
export default {
    components: {
        Indexset,
    },
    data () {
        return {
            active: 0
        };
    },
    methods: {
        handleNav (num) {
            this.active = num
        },

    }
}
</script>

<style lang="less" scoped]>
.el-container {
    min-height: 100%;

    .main {
        height: 100%;
        background: #fff;
        display: flex;
        flex: 1;
    }
}

nav {
    width: 130px;
    border-right: 1px solid #ddd;
    font-size: 14px;

    li {
        color: #333333;
        padding: 20px 30px;
        border-bottom: 1px solid #ddd;
        position: relative;
        cursor: pointer;

        &::before {
            content: "";
            left: 20px;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 4px;
            height: 4px;
            background: #333333;
            position: absolute;
            border-radius: 50%;
        }
    }

    .activeli {
        color: #3370ff;

        &::before {
            background: #3370ff;
        }
    }
}
</style>

<template>
    <div class="btnControl">
        <div class="numBox">
            <p>每行数量</p>
            <el-radio-group @change="changeNum" v-model="buttons.num">
                <div style="margin-bottom:10px;">
                    <el-radio label="3">3个</el-radio>
                    <el-radio label="4">4个</el-radio>
                    <el-radio label="5">5个</el-radio>
                </div>
                <div>
                    <el-radio label="6">6个</el-radio>
                    <el-radio label="8">8个</el-radio>
                    <el-radio label="10">10个</el-radio>
                </div>
            </el-radio-group>
            <el-button type="primary" style="margin-left: 108px" size="small" @click="closeButton">{{isShow ? '打开' : '关闭'}}</el-button>
        </div>
        <div class="bgColor">
            <p>背景颜色</p>
            <el-color-picker v-model="buttons.bgColor"></el-color-picker>
            <el-button type="primary" @click="returnColor">重置</el-button>
        </div>
        <div class="bgColor">
            <p></p>
            <div class="tips">建议图片尺寸96px*96px</div>
        </div>
        <div class="listbox">
            <vuedraggable v-model="buttons.list" v-bind="{
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
                scroll: true
            }">
                <transition-group>
                    <div class="btnGroup" v-for="(item, index) in buttons.list" :key="index">
                        <div class="itemLeft">
                            <span @click="moveClick(0, index, item)" :class="index == 0 ? 'fcccc' : ''">&lt;</span>
                            <span @click="moveClick(1, index, item)"
                                :class="index == buttons.list.length - 1 ? 'fcccc' : ''">></span>
                        </div>
                        <div class="itemMid">
                            <div class="imgBox">
                                <img v-if="item.img" :src="item.img" alt="" />
                            </div>
                            <div class="upload" @click="chooseImg(index)">选择图片</div>
                        </div>
                        <div class="itemRight">
                            <div style="margin-right: 10px">
                                <div>
                                    <span>输入文字</span>
                                    <el-input v-model="item.name"></el-input>
                                </div>
                                <div>
                                    <el-input v-model="item.page" disabled></el-input>
                                    <el-button type="primary" @click="toPageShow(index)">选择</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>
            </vuedraggable>
        </div>
        <!-- 选择跳转页面弹框 -->
        <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
            <div>
                <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
            </div>
        </el-dialog>
        <!-- 图片上传弹框 -->
        <UploadSource v-if="chooseImgFlag" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag"
            @getSource="getImgRes"></UploadSource>
    </div>
</template>
  
<script>
import vuedraggable from 'vuedraggable'
import ToPage from '@/repair/components/toPage'
import UploadSource from '@/components/uploadSource'
export default {
    props: {
        buttons: {
            type: Object,
            default: () => { }
        },
        isShow: {
            type: Boolean,
            default: () => false
        }
    },
    data () {
        return {
            toPageFlag: false,
            itemIndex: -1,
            chooseImgFlag: false,
        }
    },
    components: {
        vuedraggable,
        ToPage,
        UploadSource
    },
    methods: {
        closeButton() {
            this.isShow = !this.isShow;
            console.log(222, this.isShow);
            this.$emit('changeShow', this.isShow);
        },
        changeStatus (val) {
            this.chooseImgFlag = val
        },
        // 重置颜色
        returnColor () {
            this.buttons.bgColor = null
        },
        // 点击移动banner图位置
        moveClick (status, index, item) {
            if (status == 0) {
                // 向上移
                if (index == 0) {
                    this.$message({
                        message: '已经在最前面了~',
                        type: 'error'
                    })
                    return
                }
                this.buttons.list.splice(index, 1)
                this.buttons.list.splice(index - 1, 0, item)
            } else {
                // 向下移
                if (index == this.buttons.list.length - 1) {
                    this.$message({
                        message: '已经在最后面了~',
                        type: 'error'
                    })
                    return
                }
                this.buttons.list.splice(index + 2, 0, item)
                this.buttons.list.splice(index, 1)
            }
        },
        // 开启选择链接弹框
        toPageShow (index) {
            this.toPageFlag = true
            this.itemIndex = index
            this.$nextTick(() => {
                this.$refs.toPage.getPageArr()
            })
        },
        // 拿到跳转路径
        getPageRes (item) {
            this.buttons.list[this.itemIndex].page = item.wxroute
            this.toPageFlag = false
        },
        // 选择图片
        chooseImg (index) {
            this.itemIndex = index
            this.chooseImgFlag = !this.chooseImgFlag
        },
        // 拿到图片
        getImgRes (imglist) {
            this.buttons.list[this.itemIndex].img = imglist[0].path
        },
        changeNum (val) {
            let num = this.buttons.list.length
            if (val > num) {
                for (let i = 0; i < val - num; i++) {
                    this.buttons.list.push({
                        img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bgImg.png',
                        name: '按钮文字',
                        page: '跳转页面'
                    })
                }
            } else {
                this.buttons.list = this.buttons.list.slice(0, val)
            }
        }
    }
}
</script>
  
<style lang="less" scoped>
.btnControl {
    width: 100%;
    padding: 30px;

    .tips {
        font-size: 14px;
        color: #9a9a9a;
    }

    .numBox {
        display: flex;

        // align-items: center;
        p {
            margin-right: 20px;
        }
    }

    .bgColor {
        display: flex;
        align-items: center;
        margin: 20px 0;

        p {
            margin-right: 20px;
        }

        .el-color-picker/deep/.el-color-picker__trigger {
            width: 150px;
        }
    }

    .btnGroup {
        margin: 20px 0;
        display: flex;
        align-items: center;

        .itemLeft {
            width: 30px;
            height: 100%;

            span {
                width: 16px;
                height: 16px;
                line-height: 16px;
                text-align: center;
                border-radius: 5px;
                display: block;
                border: 1px solid #333;
                font-weight: bold;
                cursor: pointer;
            }

            span:first-child {
                transform: rotate(90deg);
            }

            span:last-child {
                transform: rotate(90deg);
                margin-top: 10px;
            }
        }

        .itemMid {
            position: relative;
            width: 90px;
            height: 90px;
            border: 1px dotted #333;
            margin-right: 10px;

            .imgBox {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                overflow: hidden;

                // background-color: skyblue;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }

            .upload {
                width: 100%;
                position: absolute;
                height: 25px;
                line-height: 25px;
                bottom: 0;
                left: 0;
                color: #e4e4e4;
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.4);
                font-size: 12px;
                text-align: center;
            }
        }

        .itemRight {
            display: flex;
            align-items: center;

            &>div {
                height: 90px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &>div {
                    display: flex;

                    span {
                        width: 120px;
                        line-height: 40px;
                    }
                }
            }
        }
    }
}

.fcccc {
    color: #ccc;
    border: 1px solid #ccc !important;
    cursor: no-drop !important;
}
</style>
  
import { render, staticRenderFns } from "./dataControl.vue?vue&type=template&id=8ecb1f28&scoped=true&"
import script from "./dataControl.vue?vue&type=script&lang=js&"
export * from "./dataControl.vue?vue&type=script&lang=js&"
import style0 from "./dataControl.vue?vue&type=style&index=0&id=8ecb1f28&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ecb1f28",
  null
  
)

export default component.exports